



































































































































































































































































import { Vue, Component } from 'vue-property-decorator'
import CommonTitle from './component/CommonTitle.vue'

@Component({
    name: 'OurService',
    components: {
        CommonTitle,
    }
})
export default class extends Vue {
    hoverIndex: number = 0

    deploymentList: any = [
        {
            label: 'Medical Check',
            img: require('@/assets/images/deployment1@3x.png')
        },
        {
            label: 'TESDA Application',
            img: require('@/assets/images/deployment2@3x.png')
        },
        {
            label: 'OWWA Application',
            img: require('@/assets/images/deployment3@3x.png')
        },
        {
            label: 'PDOS Application',
            img: require('@/assets/images/deployment4@3x.png')
        },
        {
            label: 'OEC Application',
            img: require('@/assets/images/deployment5@3x.png')
        },
        {
            label: 'Deploy to Oversea',
            img: require('@/assets/images/deployment6@3x.png')
        }
    ]
}
